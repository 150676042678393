import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

const BACKEND_ENDPOINT = 'https://5jay2kccxh2ax7maw4254tu26m0zccma.lambda-url.ap-northeast-2.on.aws';

function App() {
  const [longUrl, setLongUrl] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [error, setError] = useState('');
  const [copied, setCopied] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (longUrl.length < 5) {
      setShortUrl('');
      setError('Please enter a valid URL');
      return;
    }

    try {
      const response = await axios.post(`${BACKEND_ENDPOINT}/shorten`, {
        url: longUrl,
      });
      const { shortUrl } = response.data;
      setShortUrl(shortUrl);
      setError('');
    } catch (error) {
      setError('Failed to shorten URL. Please try again later.');
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText('https://dcinsicle.com/' + shortUrl);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  useEffect(() => {
    const path = window.location.pathname;
    if (path !== '/') {
      const key = path.substring(1);

      axios
        .get(`${BACKEND_ENDPOINT}/${key}`)
        .then((response) => {
          const { data } = response;
          const targetURL = data.targetURL;

          console.log(targetURL + ' received');

          window.location.href = `https://${targetURL}`;
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, []);

  return (
    <div className='box-container'>
      <div>　　</div>
      <div>　　</div>
      <h1>URL Shortener</h1>
      <div className='container'>
        <form onSubmit={handleSubmit}>
          <div className='input-container'>
            <input
              type='text'
              value={longUrl}
              onChange={(event) => setLongUrl(event.target.value)}
              placeholder='Enter long URL'
            />
            <button type='submit'>Shorten</button>
          </div>
        </form>

        <div className='message-box'>
          {error && <div className='error'>{error}</div>}

          {shortUrl && (
            <div className='shortened-url'>
              <h3>Shortened URL:</h3>
              <div className='short-url-container'>
                <a href={shortUrl} target='_blank' rel='noopener noreferrer'>
                  {'https://dcinsicle.com/' + shortUrl}
                </a>
                <button className='copy-icon' onClick={handleCopyToClipboard}>
                  <svg
                    xmlns='https:/www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    <rect x='9' y='9' witdh='13' height='13' rx='2' ry='2' />
                    <path d='M17 17h2a1 1 0 001-1v-2' />
                  </svg>
                </button>
                {copied && <div className='copy-message'>Copied!</div>}
              </div>
            </div>
          )}
        </div>
      </div>

      <a
        id='donate'
        href='https://toss.me/바로가기사이트'
        target='_blank'
        rel='noopener noreferrer'
      >
        후원하기 | Donate
      </a>
    </div>
  );
}

export default App;
