import React from 'react';
import { FaYoutube, FaInstagram } from 'react-icons/fa';
import './Footer.css';

function Footer() {
    return (
    <footer className="footer">
      <div className="footer-content">
        <p className='footer-text'>
            &copy; 2023 <a href="https://example.com/" target="_black" rel="noopener noreferrer">URLShortener</a>
        </p>
        <div className="social-links">
          <a href="https://www.youtube.com/@barogagisite" target="_blank" rel="noopener noreferrer" ClassName="social-icon">
            <FaYoutube/>
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" ClassName="social-icon">
            <FaInstagram/>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
